import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";

const onRedirectCallback = (appState) => {
	navigate(appState?.returnTo || "/");
};

const Auth0ProviderWithHistory = ({ children }) => {
	const domain = process.env.GATSBY_AUTH0_DOMAIN;
	const clientId = process.env.GATSBY_AUTH0_CLIENTID;
	// const audience = process.env.GATSBY_AUTH0_AUDIENCE; // If you're using Auth0's API

	// Check if window is defined (i.e., in the browser)
	const isBrowser = typeof window !== "undefined";

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				redirect_uri: "https://quotestar.co/broker/loading",
			}}
			onRedirectCallback={onRedirectCallback}
			// audience={audience} // Optional: Only if you're using Auth0's API
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;
